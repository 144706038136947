<script>
import Layout from "../../../layouts/main";
// import PageHeader from "@/components/page-header";
// import { required } from "vuelidate/lib/validators";
import { paymentMethods } from "@/state/helpers";
import * as cloneDeep from "lodash.clonedeep";
import {
  FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS,
  UPDATE_PAYMENT_METHOD_CREDENTIALS,
  // FETCH_PAYMENT_METHODS_CREDENTIALS,
  ADD_PAYMENT_METHOD_CREDENTIALS,
  FETCH_ALL_PAYMENT_CHANNEL,
  // UPDATE_ENABLE_AND_DEFAULT_STATUS
} from "@/state/actions.type";
import {
  UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE,
  UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE
} from '@/state/mutations.type';
import Multiselect from 'vue-multiselect';
 
const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export default {
  components: {
    Layout,
    // PageHeader,
    Multiselect,
  },

  data() {
    return {
      paymentChannelCreds: null,
      currentChannel: null,
      currentChannelCopy: null,
      addChannelCred: null,
      addChannelCredCopy: null,
      showPayInmodal: false,
      inViewMode:true,
      showAddCredPayInmodal: false,
      isdata: false,
      checked: true,
      checked1: false,
      paymentsMethodsData:[],
      // selected_country:"",
      selected_country_for_add_cred:"",
      mKey: ""
    };
  },

  computed: {
    isValid() {
      if (this.currentChannel) {
        const creds = this.currentChannel.creds;
        for (let cred in creds) {
          if (!creds[cred].value) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    isValidAddCred() {
      if (this.addChannelCred) {
        const creds = this.addChannelCred.creds;
        for (let cred in creds) {
          if (!creds[cred].value) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    paymentChannelCredsList() {
      return this.$store.state.paymentChannels.selectedChannelCreds;
    },
    paymentmethodCredsList() {
      return this.$store.state.paymentChannels.selectedChannelMethods;
    },

    chaiPayKey() {
      return this.$store.getters["auth/iamportKey"];
    },

    // countryList() {
    //   return this.$store.getters["paymentChannels/listOfCountry"];
    // },
    countryListForAddCred() {
      return this.$store.getters["paymentChannels/listOfCountryForAddCred"];
    },
    topBarPayoutData() {
      return this.$store.getters["paymentChannels/topBarPayoutData"];
    },
    // dataForWalletType() {
    //   return this.$store.getters["paymentChannels/dataForWalletType"];
    // },
    // dataForAllType() {
    //   return this.$store.getters["paymentChannels/dataForAllType"];
    // },
    // dataForCardType() {
    //   return this.$store.getters["paymentChannels/dataForCardType"];
    // },
    // dataForAtmCardType() {
    //   return this.$store.getters["paymentChannels/dataForAtmCardType"];
    // },
    // selectedCountry() {
    //   return this.countryList[0];
    // },
    // items() {
    //   var queryParams = new URLSearchParams(window.location.search);
    //   return [
    //     {
    //       text: this.$t("menuitems.integration.list.payconfig"),
    //       active: true
    //     },
    //     {
    //       text: this.$t("menuitems.integration.list.pay-channels"),
    //       href: '/settings/payout-channel'
    //     },
    //     {
    //       text: `${queryParams.get("channelName")}`,
    //       active: true,
    //     },
    //   ];
    // },

    // title() {
    //   return this.$t("menuitems.integration.list.pay-channels");
    //   // return this.$t("menuitems.integration.list.pay-channelstwo");
    // },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },
  },

  created() {
    // if(Object.keys(this.$store.state.paymentChannels.channels).length < 1){
    let payload = {
      country: [],
      paymentMethod: "",
      paymentType: "PAYOUT"
    };
    this.mKey = this.$route.query.mKey;
    if(this.mKey && this.isMasterMerchant) {
      payload.merchant_uuids = this.$route.query.mKey;
    }

    this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, payload);
    // }

    payload = {
      channelName: this.$route.query.channelName,
      payType: "PAYOUT",
    }
    if(this.mKey && this.isMasterMerchant) {
      payload.merchant_uuids = this.$route.query.mKey;
    }
   this.$store.dispatch(`paymentChannels/${FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS}`, payload);
    this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
  },

  watch: {
    paymentChannelCredsList() {
      this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    },
    paymentmethodCredsList() {
      this.paymentsMethodsData = cloneDeep(this.paymentmethodCredsList);
    },
    // selectedCountry() {
    //   this.selected_country = this.selectedCountry
    // },
    // selected_country() {
      // var queryParams = new URLSearchParams(window.location.search)
      // var qrcode_channelName = queryParams.get("channelName")
      // var selected_country_for_method = ""
      // if(this.paymentChannelCredsList.length > 0 && this.selected_country){
      //   this.paymentChannelCredsList.forEach((channel) => {
      //     if(channel.country == this.selected_country.key){
      //        selected_country_for_method = channel.key
      //     }
      //   });
      //   this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_METHODS_CREDENTIALS}`, {channel_key: selected_country_for_method})
      // }  
    // },
    selected_country_for_add_cred() {
      this.paymentChannelCredsList.forEach((channel) => {
        if(channel.country == this.selected_country_for_add_cred.key){
            this.addChannelCred = cloneDeep(channel);
            this.addChannelCredCopy = cloneDeep(channel);
        }
      });
    },
  },

  mounted() {
    this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    this.paymentsMethodsData = cloneDeep(this.paymentmethodCredsList);
    // this.selected_country = this.countryList[0];
  },
    
  destroyed() {
    // document.body.classList.remove("dynamic-body");
    // this.selected_country = {}
    this.$store.commit(`paymentChannels/${UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE}`, []);
    this.$store.commit(`paymentChannels/${UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE}`, []);
  },

  validations: {
    paymentChannelCreds: {},
  },

  methods: {
    ...paymentMethods,

    handlePayInSubmit() {
      // stop here if form is invalid
      this.$v.$touch();
      for (var key in this.currentChannel.creds) {
        if(this.currentChannel.creds[key].value.includes("*") && this.currentChannel.creds[key].value[0] == "*") {
          delete this.currentChannelCopy.creds[key];
        } else {
          this.currentChannelCopy.creds[key] = this.currentChannel.creds[key]
        }
      }

      let payload = {
        channelId: this.currentChannel.key,
        payChannel: this.currentChannelCopy,
        payType:"PAYOUT"
      };

      if(this.mKey && this.isMasterMerchant) {
        payload.merchant_uuids = this.mKey;
      }

      this.$store.dispatch(`paymentChannels/${UPDATE_PAYMENT_METHOD_CREDENTIALS}`, payload);
      this.hidePayInModal();
    },
    selectvaflow(p){
      this.addChannelCred.is_va_flow = p;
    },
    handleAddCredPayInSubmit() {
      // stop here if form is invalid
      this.$v.$touch();
      for (var key in this.addChannelCred.creds) {
        if(this.addChannelCred.creds[key].value.includes("*") && this.addChannelCred.creds[key].value[0] == "*") {
          delete this.addChannelCredCopy.creds[key];
        } else {
          this.addChannelCredCopy.creds[key] = this.addChannelCred.creds[key]
        }
      }

      if(this.addChannelCred.key == 'EPAY_PAYOUT'){
        this.addChannelCred.is_va_flow = true;
      }
      else if(['GBPRIMEPAY_PAYOUT', 'APPOTAPAY_PAYOUT', 'BBL_PAYOUT'].includes(this.addChannelCred.key)){
        this.addChannelCred.is_va_flow = false;
      }
      //this.addChannelCredCopy.is_va_flow = this.addChannelCred.is_va_flow;
      let payload = {
        channelId: this.addChannelCred.key,
        payChannel: this.addChannelCredCopy,
        payType:"PAYOUT",
        is_va_flow: this.addChannelCred.is_va_flow,
      }

      if(this.mKey && this.isMasterMerchant) {
        payload.merchant_uuids = this.mKey;
      }
      this.$store.dispatch(`paymentChannels/${ADD_PAYMENT_METHOD_CREDENTIALS}`, payload);

      this.hideAddCredPayInModal();
    },

    // markasEnabledAndDefault(method,cardType) {
    //   if(cardType == "card"){
    //     var data = {
    //         "action_type": "UPDATE_DEFAULT_STATUS",
    //         "method_key": method.key,
    //         "method_sub_type": method.sub_type,
    //         "is_enabled": method.is_default ? false : true
    //     }
    //   }else{
    //     data = {
    //         "action_type": "UPDATE_ENABLE_STATUS",
    //         "method_key": method.key,
    //         "method_sub_type": "",
    //         "is_enabled": method.is_enabled ? false : true
    //     }
    //   }
    //   var selected_country_for_method = ""
    //   this.paymentChannelCredsList.forEach((channel) => {
    //     if(channel.country == this.selected_country.key){
    //        selected_country_for_method = channel.key
    //     }
    //   });
    //   this.$store.dispatch(`paymentChannels/${UPDATE_ENABLE_AND_DEFAULT_STATUS}`, {
    //     statusData: data,
    //     countryForMethod: selected_country_for_method
    //   });
    // },
    
    openPayInModal(channel,viewType) {
      this.showPayInmodal = true;
      this.currentChannel = cloneDeep(channel);
      this.currentChannelCopy = cloneDeep(channel);
      if(viewType == "preview"){
        this.inViewMode = true;
      }else{
        this.inViewMode = false;
      }
    },

    hidePayInModal() {
      this.currentChannel = null;
      this.currentChannelCopy = null;
      this.showPayInmodal = false;
    },
    getChaipayWebhook() {
        var queryParams = new URLSearchParams(window.location.search);
        return `${CHAIPAY_API_URL}/payout/webhook/${queryParams.get("channelName").toLowerCase()}/${this.chaiPayKey}`;
    },

    openAddCredPayInModal() {
      if(this.countryListForAddCred.length > 0){
          this.selected_country_for_add_cred = this.countryListForAddCred[0];
          this.paymentChannelCredsList.forEach((channel) => {
            if(channel.country == this.countryListForAddCred[0].key){
                this.addChannelCred = cloneDeep(channel);
                this.addChannelCredCopy = cloneDeep(channel);
            }
          });
        this.showAddCredPayInmodal = true;
      }else{
          this.NoCountryAvailable();
      }

    },

    hideAddCredPayInModal() {
      this.addChannelCred = null;
      this.addChannelCredCopy = null;
      this.showAddCredPayInmodal = false;
    },

    onCopyWebhookURL() {
      var queryParams = new URLSearchParams(window.location.search);
      this.$notify({
        type: "success",
        text: `PortOne provided webhook for ${queryParams.get("channelName").toLowerCase()} copied to clipboard.`,
        closeOnClick: true,
      });
    },
    NoCountryAvailable() {
      this.$notify({
        type: "info",
        text: this.$t('views.payment_channels.no_more_countries_to_add'),
        closeOnClick: true,
      });
    },
    credentialFieldFormat(key){
      const text = key;
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;

    },
    convertCodeToCountry(val){
      if(val == "VN"){
          return "Vietnam"
      }else if(val== "TH"){
        return "Thailand"
      }else if(val== "SG"){
        return "Singapore"
      }else if(val== "ID"){
        return "Indonesia"
      }else if(val== "MY"){
        return "Malaysia"
      }else if(val== "PH"){
        return "Philippines"
      }else if(val== "TW"){
        return "Taiwan"
      }else if(val== "KE"){
        return "Kenya"
      }else if(val== "ZA"){
        return "South Africa"
      }else if(val== "NG"){
        return "Nigeria"
      }else if(val == "GLOBAL"){
        return "GLOBAL"
      }
    },
    selectedFile(fieldName, fileList) {
      console.log(fileList[0]);
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload =  evt => {
        this.addChannelCred.creds[fieldName].value = evt.target.result;
      }
      reader.onerror = evt => {
        console.error(evt);
      }
      
    },
    gobackChannel() {
        this.$router.push("/settings/pay-channel");
        this.$router.push({name: 'Payment Channels', query: {tab: 'Payout Channels'}});
    },
     selectedFileForUpdate(fieldName, fileList) {
      console.log(fileList[0]);
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload =  evt => {
        this.currentChannel.creds[fieldName].value = evt.target.result;
      }
      reader.onerror = evt => {
        console.error(evt);
      }
      
    },
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="">
      <div class="pt-0">
          <!-- Payment Channel tab -->
            <div class="row">
              <div data-v-254e0753="" class="col-12 mb-3" style="font-weight: bold; font-size: 18px;" v-if="isMasterMerchant && $route.query.mEmail">{{ $route.query.mEmail }}</div>
              <div class="col-xl-5 col-sm-12">  
                <div
                  class="card border"
                  style="background: rgba(220, 218, 213, 0.30);border-radius: 15px;border: 1px #DCDAD5 solid; box-shadow: none; margin-bottom: 0px;">
                  <div class="card-body" style="padding:30px;">
                      <div class="right-top-section">
                        <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                        <div class="media-body media-body-custom img-container">
                          <img
                            :src="`${topBarPayoutData.logo}`"
                            alt
                            class="avatar-custom"
                            style="object-fit: contain; display: block;"
                          />
                          <div>
                            <p style="margin-bottom: 0px;font-size: 20px;font-weight: 400;padding-left: 25px;">
                              {{ topBarPayoutData.key }}
                            </p>
                            <p style="margin-bottom: 0px;margin-top: 10px;font-size: 16px;font-weight: 325;padding-left: 25px; color:rgba(0, 0, 0, 0.50);">
                              {{ topBarPayoutData.type }}
                            </p>
                          </div>
                        </div>
                        <div style="color: rgba(0, 0, 0, 0.70); font-size: 14px; word-wrap: break-word; padding-top: 10px;line-height: normal;">
                          {{ topBarPayoutData.description }}
                        </div>
                      <!-- <b-button v-b-toggle.sidebar-right @click="openAddCredPayInModal()" class="btn btn-custom btn-primary btn-block dark-bg">+ Add Keys</b-button> -->
                        <div class="button-section">
                          <b-btn class="back-button" @click="gobackChannel">
                              <img src="@/assets/images/arrow_left.svg" alt="back" />
                            </b-btn>
                          <b-button @click="openAddCredPayInModal()" class="btn btn-custom btn-primary dark-bg">+ {{ $t('views.payouts.add_keys') }}</b-button>
                        </div>
                      </div>
                  </div>
                </div>
                <div style="margin-top: 25px; margin-bottom: 25px;" class="right-bottom-section">
                        <div class="">
                          <div
                            v-for="(data, index) in paymentChannelCredsList"
                            :key="index">
                            <div class="cred-section mt-3" v-if="data.is_enabled">
                              <!-- <div class="creds-container">
                                <div class="value-class country-class">{{convertCodeToCountry(data.country)}}</div>
                                <button class="btn btn-primary float-right btn-sm" @click="openPayInModal(data,'preview')">Manage Keys</button>
                              </div> -->
                              <div class="creds-container">
                                <div class="creds-container-top-section">
                                  <div class="img-container cursor-pointer">
                                    <div style="width: 40px;height: 40px;margin-right: 20px;">
                                      <img v-if="data.country == 'GLOBAL'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/global.png" alt />
                                      <img v-if="data.country == 'VN'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/vietnam_rounded.png" alt />
                                      <img v-if="data.country == 'TH'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/thailand.jpeg" alt />
                                      <img v-if="data.country == 'ID'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/indonesia.png" alt />
                                      <img v-if="data.country == 'SG'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/singapore.png" alt />
                                      <img v-if="data.country == 'MY'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/malaysia.svg" alt />
                                      <img v-if="data.country == 'TW'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/taiwan.png" alt />
                                      <img v-if="data.country == 'PH'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/philippines.png" alt />
                                      <img v-if="data.country == 'HK'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/hong_kong.svg" alt />
                                      <img v-if="data.country == 'JP'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/japan.png" alt />
                                      <img v-if="data.country == 'IN'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/india.svg" alt />
                                      <img v-if="data.country == 'AU'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/australia.svg" alt />
                                      <img v-if="data.country == 'KR'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/korea.svg" alt />
                                      <img v-if="data.country == 'KE'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/Kenya.png" alt />
                                      <img v-if="data.country == 'ZA'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/south_africa.png" alt />
                                      <img v-if="data.country == 'NG'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/nigeria.png" alt />
                                      <!-- <img v-if="data.country == 'MY'" style="width: 100%;height: 100%; border-radius: 50%;" src="@/assets/images/flags/malaysia.svg" alt /> -->
                                    </div>
                                    <div>
                                      <div style="font-size: 18px;font-weight: 400;" class="cursor-pointer value-class d-inline-block country-class">{{convertCodeToCountry(data.country)}}</div>
                                      <div style="color: rgba(0, 0, 0, 0.50);font-size: 14px;font-weight: 325;padding-top: 10px;">Select {{convertCodeToCountry(data.country)}} payments </div>
                                    </div>
                                  </div>
                                  <span @click="openPayInModal(data,'preview')" role="button" tabindex="0" style="float: right; color: #FC6B2D; cursor: pointer;">{{ $t('views.payment_channels.manage_keys') }}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                      <g id="Iconsax/Linear/arrowright">
                                        <path id="Vector" d="M10.8225 4.44727L15.375 8.99977L10.8225 13.5523M2.625 8.99977H15.2475" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                      </g>
                                    </svg>
                                  </span>

                                  <!-- <button class="btn btn-primary float-right btn-sm" @click="openPayInModal(data,'preview')">{{  $t("views.payment_channels.manage_keys") }}</button> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
              </div>  
                         <!-- <div
                  class="card border"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                  <div class="card-body card-body-custom">
                      <form id="modal" action="#">
                        <div class="form-group">
                          <label style="" for="webhookUrl">{{ $t('views.payouts.webhook_endpoint') }}</label>
                          <i class="ri-file-copy-line ml-2 align-middle cursor-pointer copy-icon"
                          v-clipboard:copy="getChaipayWebhook()"
                          v-clipboard:success="onCopyWebhookURL"
                          v-b-tooltip.hover
                          :title="$t('common.copy')"></i>
                          <input
                            id="webhookUrl"
                            type="text"
                            class="form-control"
                            name="webhookUrl"
                            v-clipboard:copy="getChaipayWebhook()"
                            :value="getChaipayWebhook()"
                            readonly
                          />
                          <b-tooltip target="webhookUrl">
                            <p>
                              {{ $t('views.payouts.config_tooltip') }}
                            </p>
                          </b-tooltip>
                        </div>
                      </form>
                  </div>
                </div> -->

                <div class="col-xl-12 col-sm-12">
                  <div class="card"
                    style="background: rgba(220, 218, 213, 0.3); border-radius: 15px; box-shadow: none;">
                    <div class="card-body" style="padding: 30px;">
                        <form id="modal" action="#">
                          <div class="form-group" style="margin-bottom: 0px;">
                            <label style="" for="webhookUrl">{{ $t('views.payment_channels.webhook_endpoint') }}</label>
                            <span data-v-06c6b9d8="" :title="$t('common.copy')"
                              v-b-tooltip.hover
                              v-clipboard:copy="getChaipayWebhook()"
                              v-clipboard:success="onCopyWebhookURL"
                              class="ml-2 cursor-pointer copy-icon" style="cursor: pointer; width: 39px; height: 39px; background: rgb(245, 244, 243); border-radius: 8px; display: inline-block;">
                              <svg data-v-06c6b9d8="" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                              <g data-v-06c6b9d8="" opacity="0.7" clip-path="url(#clip0_1540_47398)">
                                <path data-v-06c6b9d8="" d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path data-v-06c6b9d8="" d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              </g>
                              <defs data-v-06c6b9d8="">
                                <clipPath data-v-06c6b9d8="" id="clip0_1540_47398">
                                  <rect data-v-06c6b9d8="" width="18" height="18" fill="white"></rect>
                                </clipPath>
                              </defs>
                              </svg>
                            </span>


                            <input
                              id="webhookUrl"
                              type="text"
                              class="form-control"
                              name="webhookUrl"
                              v-clipboard:copy="getChaipayWebhook()"
                              :value="getChaipayWebhook()"
                              readonly
                            />
                            <b-tooltip target="webhookUrl">
                              <p>
                                {{ $t('views.payouts.config_tooltip') }}
                              </p>
                            </b-tooltip>
                          </div>
                        </form>
                    </div>
                  </div>
                </div>
              <!-- <div
                class="col-xl-7 col-sm-12">
                <div
                  class="card border"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                  <div class="card-body card-body-custom">
                    <div class="county-wallet-top-bar mb-3 mt-1">
                      <img src="@/assets/images/country.png" alt />
                      <p class="d-inline ml-2">Country</p>
                    </div>
                    <div class="col-md-8 row">
                      <multiselect
                        v-model="selected_country"
                        :options="countryList"
                        track-by="value" 
                        label="value"
                        placeholder="Select Country"
                        size="sm"
                        :searchable="false"
                        :multiple="false"
                        :taggable="true"
                        :allowEmpty="false"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                  <div
                  class="card border" v-if="dataForWalletType.length > 0"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                    <div class="card-body card-body-custom">
                      <div class="county-wallet-top-bar mb-3 mt-1">
                        <img src="@/assets/images/wallet.png" alt />
                        <p class="d-inline ml-2">Wallets</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForWalletType"
                        :key="index"
                        class="cred-section mt-4">
                        <div class="row">
                          <div class="col-md-3">{{ data.name }}</div>
                          <div class="col-md-8">
                          <toggle-button @change="markasEnabledAndDefault(data)"
                          :value="data.is_enabled"
                          :width=80
                          :font-size=11
                          :height=25
                          :margin=3
                          :color="{checked: '#252B3A', unchecked: '#BFCBD9', disabled: '#CCCCCC'}"
                          :labels="{checked: 'Enabled', unchecked: 'Disabled'}"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  class="card border" v-if="dataForCardType.length > 0"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                    <div class="card-body card-body-custom">
                      <div class="county-wallet-top-bar mb-3 mt-1">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline ml-2">Credit Cards</p>
                      </div>
                      <div
                        v-for="(data) in dataForCardType"
                        :key="data.key"
                        class="cred-section mt-4">
                        <div class="row">
                          <div class="col-md-3"><span class="d-inline-block align-middle">{{ data.name }}</span></div>
                          <div class="col-md-8">
                          <toggle-button @change="markasEnabledAndDefault(data)"
                          :value="data.is_enabled"
                          :width=80
                          :font-size=11
                          :height=25
                          :margin=3
                          :color="{checked: '#252B3A', unchecked: '#BFCBD9', disabled: '#CCCCCC'}"
                          :labels="{checked: 'Enabled', unchecked: 'Disabled'}"/>
                            <div class="default-class d-inline ml-5"
                            v-if="data.is_default">
                              <i class="bx ri-checkbox-circle-line check-class"></i> Default
                            </div>
                            <button
                              href="javascript:void(0);"
                              class="btn btn-primary ml-5 pt-1 pb-1"
                              v-if="!data.is_default"
                              style="font-size: 12px; border: 1px solid #DAD9D9; color: #D9D9D9; background: #fff;"
                              @click="markasEnabledAndDefault(data,'card')"
                            >
                              Make Default
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  class="card border" v-if="dataForAtmCardType.length > 0"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                    <div class="card-body card-body-custom">
                      <div class="county-wallet-top-bar mb-3 mt-1">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline ml-2">ATM Cards</p>
                      </div>
                      <div
                        v-for="(data) in dataForAtmCardType"
                        :key="data.key"
                        class="cred-section mt-4">
                        <div class="row">
                          <div class="col-md-3"><span class="d-inline-block align-middle">{{ data.name }}</span></div>
                          <div class="col-md-8">
                          <toggle-button @change="markasEnabledAndDefault(data)"
                          :value="data.is_enabled"
                          :width=80
                          :font-size=11
                          :height=25
                          :margin=3
                          :color="{checked: '#252B3A', unchecked: '#BFCBD9', disabled: '#CCCCCC'}"
                          :labels="{checked: 'Enabled', unchecked: 'Disabled'}"/>
                            <div class="default-class d-inline ml-5"
                            v-if="data.is_default">
                              <i class="bx ri-checkbox-circle-line check-class"></i> Default
                            </div>
                            <button
                              href="javascript:void(0);"
                              class="btn btn-primary ml-5 pt-1 pb-1"
                              v-if="!data.is_default"
                              style="font-size: 12px; border: 1px solid #DAD9D9; color: #D9D9D9; background: #fff;"
                              @click="markasEnabledAndDefault(data,'card')"
                            >
                              Make Default
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  class="card border" v-if="dataForAllType.length > 0"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                    <div class="card-body card-body-custom">
                      <div class="county-wallet-top-bar mb-3 mt-1">
                        <img src="@/assets/images/wallet.png" alt />
                        <p class="d-inline ml-2">All</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForAllType"
                        :key="index"
                        class="cred-section mt-4">
                        <div class="row">
                          <div class="col-md-3">{{ data.name }}</div>
                          <div class="col-md-8">
                          <toggle-button @change="markasEnabledAndDefault(data)"
                          :value="data.is_enabled"
                          :width=80
                          :font-size=11
                          :height=25
                          :margin=3
                          :color="{checked: '#252B3A', unchecked: '#BFCBD9', disabled: '#CCCCCC'}"
                          :labels="{checked: 'Enabled', unchecked: 'Disabled'}"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div> -->
            </div>
      </div>

      <!-- Payment Channel Modal -->
      <div>
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showPayInmodal
          }">
          <div v-if="currentChannel" data-simplebar class="h-100">
            <div class="rightbar-title">
              <div class="right-top-section">
                <div class="card-body-custom" style="padding: 25px;">
                  <i class="ri-pencil-fill cursor-pointer edit-button" v-if="inViewMode" @click="openPayInModal(currentChannel,'edit')"></i>
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hidePayInModal"></i>
                  <div class="media-body media-body-custom">
                    <img
                      :src="`${topBarPayoutData.logo}`"
                      alt
                      class="avatar-custom"
                      style="object-fit: contain; display: block;"
                    />
                    <p class="d-inline-block mb-0" style="font-size: 20px;font-weight: 400;margin-top: 20px;">
                      {{ topBarPayoutData.key }} {{ topBarPayoutData.type }}
                    </p>
                  </div>
                </div>
                <div class="border-sep"></div>
              </div>
            </div>
            <div style="padding: 25px;">
              <form id="modal" action="#" @submit.prevent="handlePayInSubmit">
                <div class="form-group">
                  <label>{{ $t('views.payouts.country') }}</label>
                  <div class="form-control">{{convertCodeToCountry(currentChannel.country)}}</div>
                </div>
                <div
                  class="form-group"
                  v-for="(value, key) in currentChannel.creds"
                  :key="key"
                >
                  <div v-if="currentChannel.creds[key].type == 'file'">
                    <label :for="key">{{ key }}</label>
                    <div v-if="!inViewMode" class="position-relative mb-3">
                      <div class="bulk-upload-button btn-sm">{{ $t('button.select_file') }}</div>
                      <input type="file" :id="key" multiple :name="key" @change="selectedFileForUpdate($event.target.name, $event.target.files)" class="file_Upload input-file">
                    </div>
                    <textarea v-model="currentChannel.creds[key].value" readOnly disabled class="w-100 form-control" placeholder="Preview for Keys"></textarea>
                  </div>
                  <div v-if="currentChannel.creds[key].type == 'text'">
                    <label :for="key">{{ key }}</label>
                    <input   
                      :id="key"
                      v-model="currentChannel.creds[key].value"
                      type="text"
                      class="form-control"
                      :placeholder="`Enter ${key}`"
                      :name="key"
                      :readonly="inViewMode"
                    />
                  </div>
                </div>
                <div v-if="!inViewMode" class="text-left">
                  <div class="border-sep" style="margin-bottom: 30px;margin-top: 30px;"></div>
                    <b-button style="padding: 12px 20px; font-weight: 400; color:#000; background:#fff;border: none;" class="mr-2" variant="light" @click="hidePayInModal"
                    >{{ $t('button.cancel') }}</b-button>
                  <button
                    type="submit"
                    class="btn"
                    :disabled="!isValid"
                    style="padding: 12px 20px;background: rgba(220, 218, 213, 0.30);border-radius: 12px;color: #FC6B2D;font-weight: 400;"
                  >
                    {{ $t('button.save') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hidePayInModal" class="rightbar-overlay"
        :class="{
            'active': showPayInmodal
        }"></div>
      </div>
      <!-- Payment Channel Modal For Add Cred -->
      <div>
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showAddCredPayInmodal
          }">
          <div v-if="addChannelCred" data-simplebar class="h-100">
            <div class="rightbar-title">
              <div class="right-top-section">
                <div class="card-body-custom" style="padding: 25px;">  
                   <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hideAddCredPayInModal"></i>
                  <div class="media-body media-body-custom">
                    <img
                      :src="`${topBarPayoutData.logo}`"
                      alt
                      class="avatar-custom"
                      style="object-fit: contain; display: block;"
                    />
                     <p class="d-inline-block mb-0" style="font-size: 20px;font-weight: 400;margin-top: 20px;">
                      {{ topBarPayoutData.key }} {{ topBarPayoutData.type }}
                    </p>
                  </div>
                </div>
                <div class="border-sep"></div>
              </div>
            </div>
            <div style="padding: 25px;" class="rightbar-content">
              <form id="modal" action="#" @submit.prevent="handleAddCredPayInSubmit">
                <multiselect
                  v-model="selected_country_for_add_cred"
                  :options="countryListForAddCred"
                  placeholder="Select Country"
                  track-by="value" 
                  label="value"
                  size="sm"
                  class="cursor-pointer"
                  :searchable="false"
                  :multiple="false"
                  :taggable="true"
                  :allowEmpty="false"
                  :show-labels="false"
                    style="margin-bottom: 25px;"
                ></multiselect>
                <div
                  class="form-group"
                  v-for="(value, key) in addChannelCred.creds"
                  :key="key"
                >
                  <div v-if="addChannelCred.creds[key].type == 'file'">
                    <label :for="key">{{ key }}</label>
                    <div class="position-relative">
                      <div class="bulk-upload-button btn-sm">{{ $t('button.select_file') }}</div>
                      <input type="file" :id="key" multiple :name="key" @change="selectedFile($event.target.name, $event.target.files)" class="file_Upload input-file">
                    </div>
                    <textarea v-model="addChannelCred.creds[key].value" readOnly disabled class="mt-3 w-100 form-control" placeholder="Preview for Keys"></textarea>
                  </div>
                  <div v-if="addChannelCred.creds[key].type == 'text'">
                    <input   
                      :id="key"
                      v-model="addChannelCred.creds[key].value"
                      type="text"
                      class="form-control"
                      :placeholder="`Enter ${credentialFieldFormat(key)}`"
                      :name="key"
                    />
                  </div>
                </div>
                <div class="form-group" v-if="!['GBPRIMEPAY_PAYOUT', 'APPOTAPAY_PAYOUT', 'BBL_PAYOUT', 'PAYNAMICS_PAYOUT'].includes(addChannelCred.key)">
                  <label>Use Virtual Account Flow</label>
                  <div v-if="addChannelCred.key == 'EPAY_PAYOUT'">
                    <b-form-checkbox
                      id="is_va_flow"   
                      v-model="checked"
                      name="is_va_flow"
                      switch
                      disabled 
                    >
                    </b-form-checkbox>
                  </div>
                  <div v-else>
                    <b-form-checkbox
                      id="is_va_flow"    
                      v-model="addChannelCred.is_va_flow"
                      @change=selectvaflow(addChannelCred.is_va_flow)
                      name="is_va_flow"
                      switch
                    >
                    </b-form-checkbox>
                  </div>

                    
                  </div>
                <div class="text-left">
                  <div class="border-sep" style="margin-bottom: 30px;margin-top: 30px;"></div>
                  <button
                    type="submit"
                    class="btn"
                    style="padding: 12px 20px;background: rgba(220, 218, 213, 0.30);border-radius: 12px;color: #FC6B2D;font-weight: 400;"
                    :disabled="!isValidAddCred"
                  >
                    {{ $t('button.save') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hideAddCredPayInModal" class="rightbar-overlay"
        :class="{
            'active': showAddCredPayInmodal
        }"></div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
input:focus:required:invalid {
  color: green;
}

::v-deep .nav-tabs-custom {
  margin-bottom: 24px;
}
.media-body-custom{
    display: inline-block;
    vertical-align: middle;
}
.card-body-custom{
  padding:15px 20px;
  position: relative;
}
.btn-custom{
    float: right;
    margin-top: 30px;
    background-color: #5664d2;
    border-color: #5664d2;
}

.creds-container{
  position: relative;
  border-radius: 15px; 
  border: 1px #DCDAD5 solid;
}
.creds-container.active{
  background: rgba(220, 218, 213, 0.30);
}
.creds-container-top-section{
  padding: 20px 25px 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-button{
  position: absolute;
  right: 50px;
  top: 25px;
  font-size: 15px;
}
.view-button{
  float: right;
  letter-spacing: 0px;
  color: #B3AFAF;
  font-size: 13px;
  text-decoration: underline;
}
.vue-js-switch{
  margin-bottom: 0px;
}
.check-class{
    padding-right: 5px;
    position: relative;
    top: 3px;
    color:#1cbb8c;
    font-weight: bold;
}
.county-wallet-top-bar img{
    height:18px;
    width:18px;
    display:inline-block;
    vertical-align: middle;
}
.county-wallet-top-bar p{
    display:inline-block;
    vertical-align: middle;
}
.dark-bg{
  background-color: #252B3A;
  border-color: #252B3A;
}
.right-top-section{
  position: relative;
  z-index: 1;
}
.img-container{
  display: flex;
  align-items: center;
  justify-content: left;
}
.manage-credentials{
    background-color: #eaeaea;
    border-radius: 4px;
    font-weight: 600;
}
.right-bar{
  width: 30%;
  right: -30%;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color:#252B3B;
}
.cursor-pointer{
  cursor: pointer;
}
/* .right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 0.5px solid #00246414;;
}
::v-deep .custom-multiselect .multiselect__select:before{
  z-index: 1;
} */
.file_Upload{
    opacity: 0;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0px;
    width: 140px;
    z-index: 9999;
    cursor: pointer;
}
.bulk-upload-button{
    width: 120px!important;
    text-align: center;
    color: #FFFFFF;
    background-color: #252B3A;
    cursor: pointer;
    border-radius: 5px;
    display: block;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}
.avatar-custom{
    height: 70px;
    width: 95px;
}
.copy-icon{
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 18px;
}
.right-top-section button{
  padding-left: 16px;
  padding-right: 16px;
  height: 44px;
  font-size: 14px;
 }
.button-section{
  border-top: 1px #DCDAD5 solid;
  margin-top: 30px;
}
.back-button{
    width: 44px;
    height: 44px;
    border-radius: 12px;
    padding: 0 !important;
    background: #dfdedc;
    border: none;
    margin-top:30px;
}
.copy-icon{
  position: absolute;
  right: 35px;
  top: 60px;
}
#webhookUrl{
    padding-right: 35px;
}
::v-deep .page-content label{
    color: black;
    font-size: 15px;
    margin-bottom: 10px;
}
.page-content .form-group{
    margin-bottom: 25px;
}
::v-deep .page-content select, ::v-deep .page-content textarea, ::v-deep .page-content .form-control {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
    color: #000;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border: 1px #DCDAD5 solid;
    height: auto;
}
.border-sep{
  border-top: 1px #DCDAD5 solid;
}
::v-deep .multiselect__tags {
  border-radius: 12px;
  padding: 12px 15px !important;
  height: 44px;
}
::v-deep .multiselect__input,
::v-deep .multiselect__single {
  font-size: 15px !important;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .multiselect__select{
  height: 44px;
}
::v-deep .multiselect__placeholder{
  color: #75788d !important;
}
.arrow_img.rotate_img{
  transform: rotate(180deg);
}
.county-wallet-top-bar .arrow_img{
  height: 18px;
  width: 18px;
  transition: .3s;
}
@media (max-width: 575px) {
  ::v-deep .page-title-box{
    display: block !important;
  }
  ::v-deep .page-title-box h4{
    margin-bottom: 5px!important;
  }
}
@media (max-width: 1420px) {
  .right-bar{
    width: 40%;
    right: -40%;
  }
}
@media (max-width: 400px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
</style>